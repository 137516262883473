@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --wave-circle-size: 34px;
}
.wave-container {
  position: relative;
  width: var(--wave-circle-size);
  height: var(--wave-circle-size);
}
.wave-container .circle {
  position: absolute;
  top: 0;
  left: 0;
  width: var(--wave-circle-size);
  height: var(--wave-circle-size);
  border-radius: calc(var(--wave-circle-size) / 2);
  border: 3px solid #ffac2933;
  animation: 3s linear infinite rotation;
  filter: blur(1px);
}
.wave-container .circle:nth-child(1) {
  animation-delay: 0s;
  border-color: #ffac2933;
}
.wave-container .circle:nth-child(2) {
  animation-delay: 1s;
  border-color: #ffac2933;
}
.wave-container .circle:nth-child(3) {
  animation-delay: 2s;
  border-color: #ffac2933;
}

@keyframes rotation {
  0% {
    transform: rotateZ(0) scaleX(1);
  }
  50% {
    transform: rotateZ(180deg) scaleX(1.15);
  }
  100% {
    transform: rotateZ(360deg) scaleX(1);
  }
}

#__next {
  height: 100%;
}
